<template>
  <v-container>
    <v-row>
      <v-col>
        <v-treeview
          return-object
          item-key="id"
          hoverable
          activatable
          selected-color="red"
          @update:active="ShowData"
          :items="items"
        >
          <template slot="label" slot-scope="{ item }">
            <div v-if="item.heading" class="font-italic">
              {{ item.name }}
              <v-btn icon color="primary" @click="NewItem(item)"
                ><v-icon>mdi-plus-circle</v-icon></v-btn
              >
            </div>
            <div v-if="!item.heading" class="font-weight-bold">
              {{ item.name }}
              <span class="font-weight-light">{{ GetKpiData(item) }} </span>
            </div>
          </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-6" cols="6">
        <dlgTask v-if="dialogTask" v-model="editedItemTask" />
        <dlgAvvik v-if="dialogAvvik" v-model="editedItemAvvik" />
        <dlgAvvikBehandling
          v-if="dialogAvvikBehandling"
          v-model="editedItemAvvikBehandling"
        />
        <dlgErfaring v-if="dialogErfaring" v-model="editedItemErfaring" />
        <dlgTaskBehandling
          v-if="dialogTaskBehandling"
          v-model="editedItemTaskBehandling"
        />

        <v-container v-if="dialog">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { DataSourceURL } from "../global/variables";
import KeyValue from "../global/KeyValue";

export default {
  extends: KeyValue,
  components: {
    dlgTask: () => import("../dialog/dlgTask"),
    dlgAvvik: () => import("../dialog/dlgAvvik"),
    dlgAvvikBehandling: () => import("../dialog/dlgAvvikBehandling"),
    dlgErfaring: () => import("../dialog/dlgErfaring"),
    dlgTaskBehandling: () => import("../dialog/dlgTaskBehandling"),
  },
  data: () => ({
    currentItem: "",
    items: [],
    keyProsjektID: -1,
    dialogTask: false,
    dialogAvvik: false,
    dialogAvvikBehandling: false,
    dialogErfaring: false,
    dialogTaskBehandling: false,
    editedItemTask: {},
    editedItemAvvik: {},
    editedItemAvvikBehandling: {},
    editedItemErfaring: {},
    editedItemTaskBehandling: {},
    editmode: 0, // 0 = Existing, 1 = New
    dialog: false,
    treeClickFlag: false, //There is a issue when click on New - tree click is then triggered. I have to avoid this event.

    defaultItemTask: {
      TaskID: "",
      ProsjektID: "",
      ParentTaskID: "",
      TaskStatusID: "1",
      TaskTypeID: "1",
      BrukerID: "",
      Task: "",
      ProsentFerdig: "0",
      Tidsfrist: "",
      Start: "",
      Slutt: "",
      Kommentar: "",
      EstimertTid: "0",
      EstimertKost: "0",
    },

    defaultItemAvvik: {
      AvvikID: "",
      TaskID: "",
      Overskrift: "",
      Beskrivelse: "",
      OpprettetDato: "",
      BrukerID: "",
      AvviksKost: "",
    },

    defaultItemAvvikBehandling: {
      AvvikBehandlingID: "",
      AvvikID: "",
      BrukerID: "",
      AvvikStatusID: "",
      Dato: "",
      Beskrivelse: "",
    },

    defaultItemErfaring: {
      ErfaringID: "",
      TaskID: "",
      Overskrift: "",
      Beskrivelse: "",
      Dato: "",
      EstimertBesparelse: "",
    },

    defaultItemTaskBehandling: {
      TaskBehandlingID: "",
      SprintID: "",
      TaskID: "",
      TaskStatusID: "",
      BrukerID: "",
      Kommentar: "",
    },
  }),
  created() {
    this.keyProsjektID = this.$route.params.ProsjektID;
    //this.defaultItem.ProsjektID = this.keyProsjektID;
    //this.initialize();
    // this.GetAvdelingCode();
    // this.GetChickenTilgangCode();
    // this.GetJobbTittelCode();
    // this.GetStillingsTypeCode();

    this.GetBrukerCode();
    this.GetTaskStatusCode();
    this.GetTaskTypeCode();

    //this.GetBrukerCode();

    this.GetAvvikStatusCode();
    //this.GetBrukerCode();

    //this.GetTaskStatusCode();

    this.GetData(this.keyProsjektID);
  },
  methods: {
    dialogStatus() {
      return (
        this.dialogTask ||
        this.dialogAvvik ||
        this.dialogAvvikBehandling ||
        this.dialogErfaring ||
        this.dialogTaskBehandling
      );
    },
    CloseDialog() {
      this.dialogTask = false;
      this.dialogAvvik = false;
      this.dialogAvvikBehandling = false;
      this.dialogErfaring = false;
      this.dialogTaskBehandling = false;
    },

    GetData(ProsjektID) {
      this.editmode = 0;
      axios({
        method: "get",
        params: { ProsjektID: ProsjektID },
        url: DataSourceURL + "/TaskTree/GetData",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          this.isLoading = false;
          if (!res.data.ErrorStatus) {
            this.items = res.data.DataList;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          alert(
            `There was an error in reading /PartnerTree/GetPartnerData'. See details: ${err}`
          );
        });
    },

    GetTask(TaskID) {
      axios({
        method: "get",
        params: { TaskID: TaskID },
        url: DataSourceURL + "/Task/GetTask",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItemTask = res.data.DataList;
            this.CloseDialog();
            this.dialogTask = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Task/GetTask. See details: ${err}`
          );
        });
    },

    GetAvvik(AvvikID) {
      axios({
        method: "get",
        params: { AvvikID: AvvikID },
        url: DataSourceURL + "/Avvik/GetAvvik",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItemAvvik = res.data.DataList;
            this.CloseDialog();
            this.dialogAvvik = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Avvik/GetAvvik. See details: ${err}`
          );
        });
    },

    GetAvvikBehandling(AvvikBehandlingID) {
      axios({
        method: "get",
        params: { AvvikBehandlingID: AvvikBehandlingID },
        url: DataSourceURL + "/AvvikBehandling/GetAvvikBehandling",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItemAvvikBehandling = res.data.DataList;
            this.CloseDialog();
            this.dialogAvvikBehandling = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /AvvikBehandling/GetAvvikBehandling. See details: ${err}`
          );
        });
    },

    GetErfaring(ErfaringID) {
      axios({
        method: "get",
        params: { ErfaringID: ErfaringID },
        url: DataSourceURL + "/Erfaring/GetErfaring",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItemErfaring = res.data.DataList;
            this.CloseDialog();
            this.dialogErfaring = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /Erfaring/GetErfaring. See details: ${err}`
          );
        });
    },

    GetTaskBehandling(TaskBehandlingID) {
      axios({
        method: "get",
        params: { TaskBehandlingID: TaskBehandlingID },
        url: DataSourceURL + "/TaskBehandling/GetTaskBehandling",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token"),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Content-Type",
          "Access-Control-Allow-Methods": "GET",
        },
      })
        .then((res) => {
          if (!res.data.ErrorStatus) {
            this.editedItemTaskBehandling = res.data.DataList;
            this.CloseDialog();
            this.dialogTaskBehandling = true;
          } else {
            alert(res.data.FeedbackText);
          }
        })
        .catch((err) => {
          alert(
            `There was an error in reading /TaskBehandling/GetTaskBehandling. See details: ${err}`
          );
        });
    },

    ShowData(item) {
      if (this.treeClickFlag) {
        this.treeClickFlag = false;
      } else if (item.length > 0) {
        this.ShowDataDo(item[0], 0);
      } else {
        this.CloseDialog();
      }
    },

    NewItem(newVal) {
      console.log("NewItem");

      switch (String(newVal.id).slice(0, 1)) {
        case "A":
          // Task
          this.editmode = 1;
          this.treeClickFlag = true;
          this.editedItemTask = Object.assign({}, this.defaultItemTask);
          this.editedItemTask.ProsjektID = this.keyProsjektID;
          this.currentItem = newVal;
          this.CloseDialog();
          this.dialogTask = true;
          break;
        case "C":
          // Avvik
          this.editmode = 1;
          this.treeClickFlag = true;
          this.editedItemAvvik = Object.assign({}, this.defaultItemAvvik);
          this.editedItemAvvik.TaskID = String(newVal.id).slice(1);
          this.currentItem = newVal;
          this.CloseDialog();
          this.dialogAvvik = true;
          break;
        case "E":
          // AvvikBehandling
          this.editmode = 1;
          this.treeClickFlag = true;
          this.editedItemAvvikBehandling = Object.assign(
            {},
            this.defaultItemAvvik
          );
          this.editedItemAvvikBehandling.AvvikID = String(newVal.id).slice(1);
          this.currentItem = newVal;
          this.CloseDialog();
          this.dialogAvvikBehandling = true;
          break;
        case "G":
          // Erfaring
          this.editmode = 1;
          this.treeClickFlag = true;
          this.editedItemErfaring = Object.assign({}, this.defaultItemErfaring);
          this.editedItemErfaring.TaskID = String(newVal.id).slice(1);
          this.currentItem = newVal;
          this.CloseDialog();
          this.dialogErfaring = true;
          break;
        case "I":
          // Task behandling
          this.editmode = 1;
          this.treeClickFlag = true;
          this.editedItemTaskBehandling = Object.assign(
            {},
            this.defaultTaskBehandling
          );
          this.editedItemTaskBehandling.SprintID = String(newVal.id).slice(1);
          this.currentItem = newVal;
          this.CloseDialog();
          this.dialogTaskBehandling = true;
          break;
        default:
          this.CloseDialog();
      }
    },

    ShowDataDo(newVal, editmode) {
      switch (String(newVal.id).slice(0, 1)) {
        case "B":
          // Task
          this.editmode = editmode;
          this.currentItem = newVal;
          this.GetTask(String(newVal.id).slice(1));
          break;
        case "D":
          // Avvik
          this.editmode = editmode;
          this.currentItem = newVal;
          this.GetAvvik(String(newVal.id).slice(1));
          break;
        case "F":
          // AvvikBehandling
          this.editmode = editmode;
          this.currentItem = newVal;
          this.GetAvvikBehandling(String(newVal.id).slice(1));
          break;
        case "H":
          // Erfaring
          this.editmode = editmode;
          this.currentItem = newVal;
          this.GetErfaring(String(newVal.id).slice(1));
          break;
        case "J":
          // Task behandling
          this.editmode = editmode;
          this.currentItem = newVal;
          this.GetTaskBehandling(String(newVal.id).slice(1));
          break;
        default:
          this.CloseDialog();
      }
    },

    GetKpiData(item) {
      let d = "";
      // alert(item.name);
      // alert(item.children.length);
      if (item.children != null) {
        let i = 0;
        for (i = 0; i < item.children.length; i++) {
          if (i == 0) d = "  -  ";
          if (i != 0) d = d + "  /  ";
          d =
            d + item.children[i].name + ": " + item.children[i].children.length;
        }
      }
      return d;
    },
    close() {
      this.CloseDialog();
    },
    save() {
      console.log("save");
      if (this.dialogTask) {
        this.DoSaveTask(this.editedItemTask);
      } else if (this.dialogAvvik) {
        this.DoSaveAvvik(this.editedItemAvvik);
      } else if (this.dialogAvvikBehandling) {
        this.DoSaveAvvikBehandling(this.editedItemAvvikBehandling);
      } else if (this.dialogErfaring) {
        this.DoSaveErfaring(this.editedItemErfaring);
      } else if (this.dialogTaskBehandling) {
        this.DoSaveTaskBehandling(this.editedItemTaskBehandling);
      }
    },

    DoSaveTask(d) {
      const editedItem = d;
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editmode == 0) {
        myUrl = DataSourceURL + "/Task/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Task/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK
              if (this.editmode == 0) {
                //Update
                this.currentItem.name = editedItem.Task;
              } else {
                this.GetData(this.keyProsjektID);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    DoSaveAvvik(d) {
      const editedItem = d;
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editmode == 0) {
        myUrl = DataSourceURL + "/Avvik/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Avvik/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK
              if (this.editmode == 0) {
                //Update
                this.currentItem.name = editedItem.Overskrift;
              } else {
                this.GetData(this.keyProsjektID);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    DoSaveAvvikBehandling(d) {
      const editedItem = d;
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editmode == 0) {
        myUrl = DataSourceURL + "/AvvikBehandling/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/AvvikBehandling/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK
              if (this.editmode == 0) {
                //Update
                this.currentItem.name = editedItem.Beskrivelse;
              } else {
                this.GetData(this.keyProsjektID);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    DoSaveTaskBehandling(d) {
      const editedItem = d;
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editmode == 0) {
        myUrl = DataSourceURL + "/TaskBehandling/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/TaskBehandling/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK
              if (this.editmode == 0) {
                //Update
                this.currentItem.name = editedItem.Kommentar;
              } else {
                this.GetData(this.keyProsjektID);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },

    DoSaveErfaring(d) {
      const editedItem = d;
      let submit = true;

      submit = true;
      let myUrl;
      if (this.editmode == 0) {
        myUrl = DataSourceURL + "/Erfaring/DoSaveUpdate";
      } else {
        myUrl = DataSourceURL + "/Erfaring/DoSaveNew";
      }

      if (submit) {
        axios({
          method: "post",
          url: myUrl,
          data: { data: editedItem },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-token"),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Content-Type",
            "Access-Control-Allow-Methods": "POST",
          },
        })
          .then((res) => {
            if (!res.data.ErrorStatus) {
              //Save OK
              if (this.editmode == 0) {
                //Update
                this.currentItem.name = editedItem.Overskrift;
              } else {
                this.GetData(this.keyProsjektID);
              }
            } else {
              alert(res.data.FeedbackText);
            }
          })
          .catch((err) => {
            alert(`There was an error saveing your form. See details: ${err}`);
          });
      }
    },
    setDialogStatus() {
      this.dialog = this.dialogStatus();
    },
  },

  watch: {
    active(newVal, oldVal) {
      if (newVal !== oldVal) {
        //this.ShowDataDo(newVal);
      }
    },

    dialogTask(val) {
      this.setDialogStatus();
    },
    dialogAvvik(val) {
      this.setDialogStatus();
    },
    dialogAvvikBehandling(val) {
      this.setDialogStatus();
    },
    dialogErfaring(val) {
      this.setDialogStatus();
    },
    dialogTaskBehandling(val) {
      this.setDialogStatus();
    },
  },
};
</script>
